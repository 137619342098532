import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { Link, graphql } from "gatsby"
// import { Container } from "reactstrap"
import Swiper from "@components/swiper/swiper"
import PodCastSwiper from "@components/swiper/swiper"
// import SampleForm from "@components/form/form"
import Hero from "@components/hero"
import {
	LazyLoadImage,
	LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import WebriQForm from "@webriq/gatsby-webriq-form"
import "../components/form/form.css"

const Contact = () => (
	<Layout>
		<SEO title="Contact Us" description="Contact Page" />
		<div class="page-headline">
			<div class="container">
				<div class="section-heading text-center">
					<h5 class="font-weight-bold text-uppercase text-orange flair mb-0">
						REACH OUT TO US
					</h5>
					<h1>Contact Us</h1>
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<section id="contact-page" className="component">
					<div className="contact-page-inner">
						<div className="container px-5 px-md-3 row">
							<div className="intro mb-4 col-md-6">
								<div className="row">
									<div className="col-md-12 mx-auto">
										<h2 className="text-orange">
											We want to hear&nbsp;
											<span className="text-primary">from you!</span>
										</h2>
										<p className="text-muted">
											If you have Questions or Concerns or would like further
											information about our programs please contact us by
											calling or emailing our NARM Administrator, Autumn Smith,
											or completing the form.
										</p>
										<hr />
									</div>

									<div className="intro mb-4">
										<div class="text-left contact-icons row">
											<i class="col-md-3 fa fa-phone text-muted mb-3 ml-3" />

											<p className="col-md-6 text-muted "><a href="tel:7076218945">
								707-621-8945
							</a></p>
										</div>

										<div className="text-left  contact-icons row">
											<i class="col-md-3 fa fa-envelope text-muted mb-3 ml-3" />

											<p className="col-md-6 text-muted ">
												<a href="mailto:support@narmtraining.com">
								support@narmtraining.com
							</a>
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-6 mx-auto">
								<WebriQForm
									name="Contact Form"
									data-form-id="198500ae-d4ad-46b4-8d08-a557f713aa55"
									data-thankyou-url="/thank-you"
									className="contact p-3"
								>
									<div className="form-group align-items-center row m-0">
										<div className="col-md px-0 mb-3 mb-md-0">
											<input
												className="form-control mb-md-0"
												type="text"
												placeholder="Name"
												name="Name"
											/>
										</div>
									</div>
									<div className="form-group align-items-center row m-0">
										<div className="col-md px-0 mb-3 mb-md-0">
											<input
												className="form-control mb-md-0"
												type="text"
												placeholder="Enter your email address"
												name="Email"
											/>
										</div>
									</div>

									<div className="form-group align-items-center row m-0">
										<div className="col-md px-0 mb-3 mb-md-0">
											<textarea
												className="form-control mb-md-0"
												type="textarea"
												placeholder="Message"
												name="Message"
											/>
										</div>
									</div>
									<div className="form-group mb-4">
										<div className="webriq-recaptcha" />
									</div>
									<div className="form-group align-items-center row m-0">
										<div className="col-md-auto px-0">
											<p className="d-flex flex-column flex-sm-row justify-content-start justify-content-md-center mb-0">
												<button
													className="btn btn-primary float-sm-right arrow"
													type="submit"
												>
													SEND MESSAGE
												</button>
											</p>
										</div>
									</div>
								</WebriQForm>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</Layout>
)

export default Contact
